<template>
  <van-tabs v-model="active">
    <van-tab title="门锁" name="lock"><tab1-vue /></van-tab>
    <van-tab title="入住机" name="checkIn"><tab2-vue /></van-tab>
    <van-tab title="询价记录" name="price"><tab3-vue /></van-tab>
  </van-tabs>
</template>
<script>
import tab1Vue from "./introduce/tab1.vue";
import tab2Vue from "./introduce/tab2.vue";
import tab3Vue from "./introduce/tab3.vue";
export default {
  components: {
    tab1Vue,
    tab2Vue,
    tab3Vue,
  },
  mounted() {
  },
  data() {
    return {
      active: "lock",
    };
  },
};
</script>
<style lang="less" scoped>
/deep/.van-tabs__content {
  height: calc(100vh - 90px);
  overflow-x: hidden;
}
</style>
