import request from '@/utils/request'
const BASE_URL = process.env.VUE_APP_API_BASE_URL


/**
 * 智能硬件询价表单提交
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function saveSmartDeviceEnquiry(data) {
    return request({
        url: `${BASE_URL}api/v1/user/save_smart_device_enquiry`,
        method: 'post',
        data
    })
}

/**
 * 智能硬件询价记录
 * @param {*} data 
 * @returns 
 */

export async function getSmartDeviceEnquiryList(data) {
    return request({
        url: `${BASE_URL}api/v1/user/get_smart_device_enquiry_list`,
        method: 'get',
        data,
        loading: false
    })
}



export default {
    saveSmartDeviceEnquiry,
    getSmartDeviceEnquiryList
}