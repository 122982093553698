<template>
  <div class="ai_lock">
    <img class="ai_lock_banner" src="~@/assets/AIhardware/AILock.png" />
    <div class="api_channel">
      <div class="api_channel_item">
        <img src="~@/assets/AIhardware/huohe.png" />
        <div class="channel_info">
          <div class="channel_info_title">火河</div>
          <span class="channel_info_text">· 服务人数超80000万人次</span><br />
          <span class="channel_info_text">· 全国服务城市超过300个</span><br />
          <span class="channel_info_text">· 客服7*24小时全程守护</span>
        </div>
      </div>
      <div class="api_channel_item">
        <img src="~@/assets/AIhardware/yunding.png" />
        <div class="channel_info">
          <div class="channel_info_title">云丁</div>
          <span class="channel_info_text">· 服务人数超80000万人次</span><br />
          <span class="channel_info_text">· 全国服务城市超过300个</span><br />
          <span class="channel_info_text">· 客服7*24小时全程守护</span>
        </div>
      </div>
      <div class="api_channel_item">
        <img src="~@/assets/AIhardware/youdian.png" />
        <div class="channel_info">
          <div class="channel_info_title">优电</div>
          <span class="channel_info_text">· 服务人数超80000万人次</span><br />
          <span class="channel_info_text">· 全国服务城市超过300个</span><br />
          <span class="channel_info_text">· 客服7*24小时全程守护</span>
        </div>
      </div>
    </div>
    <div style="padding: 12px 16px 46px">
      <van-button type="primary" size="large"  @click="goToAIInquiry"
        ><span style="font-weight: 600">我要询价</span></van-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    goToAIInquiry(){
      this.$router.push('/AIhardware/AIInquiry')
    }
  },
  mounted(){
    //console.log("this",this);
    //this.$loading.show('RNM')
  }
};
</script>
<style lang="less" scoped>
.ai_lock {
  width: 100%;
  font-size: 0;
  background: #ffffff;
  min-height: calc(100vh - 90px);
  .ai_lock_banner {
    width: 100%;
  }
  .api_channel {
    background: #161616;
    margin-top: -1px;
    padding: 16px 0 12px;
    .api_channel_item {
      width: 87.2%;
      height: 128px;
      background: linear-gradient(180deg, #dff7f0 0%, #fafafa 100%);
      border-radius: 8px;
      margin: 0 auto 12px;
      display: flex;
      padding: 16px;
      img {
        width: 40px;
        height: 40px;
      }
      .channel_info {
        width: calc(100% - 84px);
        margin-left: 12px;
        .channel_info_title {
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          line-height: 22px;
          font-size: 16px;
          padding-bottom: 4px;
        }
        .channel_info_text {
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
