<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div
        class="ask_price_item"
        v-for="item in list"
        :key="item.id"
        :title="item"
      >
        <div class="ask_price_card">
          <img v-if="item.brand==='火河'" src="~@/assets/AIhardware/huohe.png" alt="" srcset="" />
          <img v-if="item.brand==='睿沃'" src="~@/assets/AIhardware/ruiwo.png" alt="" srcset="" />
          <img v-if="item.brand==='优电'" src="~@/assets/AIhardware/youdian.png" alt="" srcset="" />
          <img v-if="item.brand==='云丁'" src="~@/assets/AIhardware/yunding.png" alt="" srcset="" />
          <div class="channel_info">
            <div class="channel_info_title">
              {{ item.brand }} - {{ item.device_type }}
            </div>
            <p class="channel_info_text">
              申请人：{{ item.name }}<i class="channel_info_line"></i>{{ item.phone }}
            </p>
            <p class="channel_info_text">
              设备数量：{{ item.quantity_required }}
            </p>
            <p class="channel_info_text">申请时间：{{ item.apply_time }}</p>
          </div>
        </div>
      </div>
      <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
    </van-list>
  </van-pull-refresh>
</template>
<script>
import { getSmartDeviceEnquiryList } from "@/services/AIhardware";
export default {
  data() {
    return {
      list: [],
      // list加载状态
      loading: false,
      //没有更多了
      finished: false,
      //是否处于加载中状态
      refreshing: false,
      pageInfo: {
        page: 1,
        page_size: 10,
      },
    };
  },
  methods: {
    onLoad() {
      getSmartDeviceEnquiryList(this.pageInfo).then((res) => {
        this.loading = false;
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }
        this.list.push(...res.ret_data.list);
        this.pageInfo.page += 1;
        if (this.list.length >= res.ret_data.total) {
          this.finished = true;
        }
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pageInfo.page = 1;
      this.onLoad();
    },
  },
};
</script>
<style lang="less" scoped>
.ask_price_item {
  height: 138px;
  padding-top: 10px;
  .ask_price_card {
    width: 93.6%;
    margin: 0 auto;
    height: 128px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    padding: 16px;
    img {
      width: 40px;
      height: 40px;
    }
    .channel_info {
      width: calc(100% - 84px);
      margin-left: 12px;
      .channel_info_title {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        font-size: 16px;
        padding-bottom: 4px;
      }
      .channel_info_text {
        font-weight: 400;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 20px;
        padding-bottom: 4px;
        display: flex;
        align-items: center;
      }
      .channel_info_line {
        width: 1px;
        height: 12px;
        background: rgba(0, 0, 0, 0.08);
        display: block;
        margin: 0 6px;
      }
    }
  }
}
</style>
